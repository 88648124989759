import { walkError } from './_util/walk-error.js';
export const AUTHENTICATION_ERROR_CODE = 'FIDANT_AUTHENTICATION_ERROR';
export const hasAuthenticationError = (error) => {
    if (!(error instanceof Error)) {
        return false;
    }
    for (const e of walkError(error)) {
        if (e instanceof AuthenticationError) {
            return true;
        }
    }
    return false;
};
export class AuthenticationError extends Error {
    name = 'AuthenticationError';
    // [next@14.0.3] apparently error digests are not preserved for errors thrown by promises in `use()`.
    // They're supposed to be, since they are when thrown directly from a server component.
    // (See: next.git/test/e2e/app-dir/app/app/error/server-component/custom-digest)
    digest = AUTHENTICATION_ERROR_CODE;
    static digest = AUTHENTICATION_ERROR_CODE;
    constructor(message = 'Authentication required', options) {
        super(message, options);
        Error.captureStackTrace(this, new.target);
    }
    static [Symbol.hasInstance](error) {
        return error instanceof Error && 'digest' in error && error.digest === this.digest;
    }
}
