import type { LinkProps } from '@primer/react';
import { Link as PrimerLink } from '@primer/react';
import type { BetterSystemStyleObject } from '@primer/react/lib-esm/sx';
import { merge as mergeSx } from '@primer/react/lib-esm/sx';
import { forwardRef, useMemo } from 'react';
export type { LinkProps } from '@primer/react';

// The following wrapper fixes a hydration mismatch error:
// https://github.com/primer/react/issues/1455
// > Warning: Prop `muted` did not match. Server: "undefined" Client: "true"
const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ muted, hoverColor, sx: sxProp = null, ...props }: LinkProps, ref) => {
    if (muted) {
      hoverColor ||= 'accent.fg';
    }
    const muteStyle = useMemo(() => (muted ? { color: 'fg.muted' } : null), [muted]);
    const hoverStyle = useMemo(() => (hoverColor ? { ':hover': { color: hoverColor } } : null), [hoverColor]);
    const sx = useMemo(
      () =>
        !muteStyle
          ? sxProp
          : (mergeSx<BetterSystemStyleObject>).all([muteStyle, hoverStyle, sxProp].filter(x => x != null)),
      [muteStyle, hoverStyle, sxProp]
    );
    return <PrimerLink {...props} sx={sx} ref={ref} />;
  }
);
Link.displayName = 'Link';

export { Link };
export default Link;
