'use client';

export { defaultColorMode, useColorSchemeVar } from '@primer/react/lib-esm/ThemeProvider';
export type { ColorModeWithAuto } from '@primer/react/lib-esm/ThemeProvider';

import type { default as ThemeObject } from '@primer/react/lib-esm/theme';
import * as Primer from '@primer/react/lib-esm/ThemeProvider';
import type { SimplifyDeep } from 'type-fest/source/simplify-deep';

import type { Values } from '@fidant-io/util';

export type { ThemeColorPaths, ThemeShadowPaths } from '@primer/react/lib-esm/theme';

type ThemeObject = typeof ThemeObject;

export type ColorScheme = keyof ThemeObject['colorSchemes'];

// NOTE: These are nested weird. "{colors: {colors: {…}, shadows: {…}}, shadows: { colors: …},"
type ColorSchemeVars = Required<SimplifyDeep<Required<Values<ThemeObject['colorSchemes']>['colors']>>>;

// "ThemeColorPaths" is defined in terms of an un-exported "ThemeColor" type :-/
// And "Theme" itself is just `{[key: string]: any}`!
export interface ThemeVars extends ThemeObject, ColorSchemeVars {}

export interface Theme extends ThemeObject {}

// Not exported for some reason.
export type ColorMode = Exclude<Primer.ColorModeWithAuto, 'auto'>;

export type ThemeProviderProps = Omit<Primer.ThemeProviderProps, 'theme'> & {
  theme?: Theme;
};

type OrigUseThemeResult = ReturnType<typeof Primer.useTheme>;
type UseThemeResultOverrides = {
  theme?: ThemeVars | undefined;
  colorScheme?: ColorScheme | undefined;
};
export type UseThemeResult = {
  [K in keyof OrigUseThemeResult]: K extends keyof UseThemeResultOverrides
    ? UseThemeResultOverrides[K]
    : OrigUseThemeResult[K];
};

export const useTheme = Primer.useTheme as () => UseThemeResult;

export const ThemeProvider = Primer.ThemeProvider as React.FC<React.PropsWithChildren<ThemeProviderProps>>;

export default ThemeProvider;
