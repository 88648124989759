/* eslint-disable @typescript-eslint/no-unsafe-member-access */
'use client';

import type { SxProp } from '@primer/react';
import { default as Timeline_ } from '@primer/react/lib-esm/Timeline/Timeline';
import type React from 'react';
import type { StyledComponentBase } from 'styled-components';

export const Timeline: StyledComponentBase<
  'div',
  object,
  {
    clipSidebar?: boolean | undefined;
  } & SxProp
> = Timeline_;
export const TimelineItem: StyledComponentBase<
  'div',
  object,
  {
    condensed?: boolean | undefined;
  } & SxProp
> = Timeline_.Item;
export const TimelineBody: StyledComponentBase<'div', object, SxProp> = Timeline_.Body;
export const TimelineBreak: StyledComponentBase<'div', object, SxProp> = Timeline_.Break;

export type TimelineBadgeProps = {
  children?: React.ReactNode;
} & SxProp;
export const TimelineBadge: {
  (props: TimelineBadgeProps): React.ReactNode;
  displayName: string;
} = Timeline_.Badge;
