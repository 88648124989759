'use client';

import type { RelativeTimeElement, RelativeTimeUpdatedEvent } from '@github/relative-time-element';
import type { SxProp } from '@primer/react';
import { RelativeTime as RelativeTime_ } from '@primer/react';
import type React from 'react';
// New version messed up the type of the RelativeTime component's props
// collapsing them down to 'never'

export type {
  Format,
  FormatStyle,
  RelativeTimeUpdatedEvent,
  ResolvedFormat,
  Tense,
} from '@github/relative-time-element';

export type RelativeTimeUpdatedEventHandler = (_: RelativeTimeUpdatedEvent) => void;

export type RelativeTimeProps = Partial<RelativeTimeElement> & SxProp;

export const RelativeTime = RelativeTime_ as never as React.FC<RelativeTimeProps>;
