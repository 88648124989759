/* eslint-disable @typescript-eslint/no-unsafe-member-access */

'use client';

import type { SxProp } from '@primer/react';
import { default as Header_ } from '@primer/react/lib-esm/Header/Header';
import type { StyledComponentBase } from 'styled-components';

// The exported types in @primer/react as of ^36 all resolve to 'any' :-/

export const Header: StyledComponentBase<'header', object, SxProp> = Header_;
export const HeaderItem: StyledComponentBase<
  'div',
  object,
  {
    full?: boolean | undefined;
  } & SxProp
> = Header_.Item;
export const HeaderLink: StyledComponentBase<
  'a',
  object,
  {
    to?: string | Location | undefined;
  } & SxProp
> = Header_.Link;
