import Image from 'next/image';
import type { ComponentProps } from 'react';

import style from './style.module.scss';

type ImageProps = ComponentProps<typeof Image>;
type LogoOptionalKeys = 'width' | 'height' | 'alt' | 'priority';

export type FidantLogoProps = Omit<ImageProps, 'src' | LogoOptionalKeys> & Pick<Partial<ImageProps>, LogoOptionalKeys>;
export const FidantLogo = ({
  alt = 'Fidant Logo',
  className = '',
  width = 100,
  height = 100,
  priority = true,
  ...props
}: FidantLogoProps) => (
  <Image
    src="/fidant-logo.svg"
    alt={alt || 'Fidant Logo'}
    className={`${className} ${style.logo}`}
    priority={priority}
    width={width}
    height={height}
    {...props}
  />
);

export const FidantLogo32 = (props: Omit<FidantLogoProps, 'width' | 'height'>) => (
  <FidantLogo width={32} height={32} {...props} />
);
