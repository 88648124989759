'use client';

// Oh wow: @primer/react exports "Dialog" which is the v1 version.
// But the "real" one is Dialog/Dialog!
// Also, I get errors like this:
// > Internal error: ReferenceError: document is not defined
// > at initialFocusRef (webpack://fidant-io/.yarn/__virtual__/@primer-react-virtual-ab8711fde5/0/cache/@primer-react-npm-36.27.0-fbdcd0e442-eea2a1e182.zip/node_modules/@primer/react/lib-esm/hooks/useFocusTrap.js?6592:21:38)
// >   at eval (webpack://fidant-io/.yarn/__virtual__/@primer-react-virtual-ab8711fde5/0/cache/@primer-react-npm-36.27.0-fbdcd0e442-eea2a1e182.zip/node_modules/@primer/react/lib-esm/Dialog/Dialog.js?fd81:166:15)
// Which tells me that it's attempting to do SSR (badly) within the component.

import dynamic from 'next/dynamic';

// import { Dialog } from '@primer/react/lib-esm/Dialog/Dialog';
export const Dialog = dynamic(async () => (await import('@primer/react/lib-esm/Dialog/Dialog')).Dialog, { ssr: false });

export type {
  DialogButtonProps,
  DialogHeaderProps,
  DialogHeight,
  DialogProps,
  DialogWidth,
} from '@primer/react/lib-esm/Dialog/Dialog';
