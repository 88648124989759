import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-1168aeecbf/0/cache/next-npm-14.2.13-4a291beaf9-152928801d.zip/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/app/(dashboard)/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/components/logo/client.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/components/logo/style.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseOrAuthBoundary"] */ "/srv/app/packages/web-ui/src/components/SuspenseOrAuthBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VerticalDivider"] */ "/srv/app/packages/web-ui/src/components/VerticalDivider.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/lib/primer/index.ts");
