'use client';

import { ActionList } from '@primer/react/lib-esm/ActionList';

export {
  /**
   * Collection of list-related components.
   */
  ActionList,
};

/** Visually separates `Item`s or `Group`s in an `ActionList`. */
export const ActionListDivider = ActionList.Divider;

/** Collects related `Items` in an `ActionList`. */
export const ActionListGroup = ActionList.Group;

/** Heading for `ActionList.Group` */
export const ActionListGroupHeading = ActionList.GroupHeading;

/** An actionable or selectable `Item` */
export const ActionListItem = ActionList.Item;

/** Icon (or similar) positioned before `Item` text. */
export const ActionListLeadingVisual = ActionList.LeadingVisual;

/** Icon (or similar) positioned after `Item` text. */
export const ActionListTrailingVisual = ActionList.TrailingVisual;

/** Heading for an `ActionList`. */
export const ActionListHeading = ActionList.Heading;

/** Secondary text which provides additional information about an `Item`. */
export const ActionListDescription = ActionList.Description;

/** A `Item` that renders a full-size anchor inside ListItem */
export const ActionListLinkItem = ActionList.LinkItem;
