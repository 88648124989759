'use client';

import Box from '@primer/react/lib-esm/Box/Box';
import { NavList } from '@primer/react/lib-esm/NavList/NavList';
import type { BetterSystemStyleObject } from '@primer/react/lib-esm/sx';
import { merge } from '@primer/react/lib-esm/sx';

export {
  /**
   * This is a private `NavBox` (styled `nav`) wrapping an `ActionList`
   */
  NavList,
};

/**
 * An actionable or selectable `Item`.
 *
 * Normally just a `ActionList.LinkItem`, unless it contains a NavListSubNav.
 */
export const NavListItem = ({
  children,
  leadingVisual,
  trailingVisual,
  ...props
}: React.ComponentProps<typeof NavListItem_> & {
  leadingVisual?: React.ReactNode;
  trailingVisual?: React.ReactNode;
}) => {
  // There's a bug in NavItem via ActionList.LinkItem.
  // https://github.com/primer/react/issues/4427
  // https://github.com/primer/react/issues/4367
  // -> https://github.com/primer/react/pull/4392
  const isActive = props['aria-current'] && props['aria-current'] !== 'false';
  return (
    <NavListItem_ {...props}>
      {leadingVisual && (
        <NavListLeadingVisual sx={{ display: 'flex', alignItems: 'center' }}>{leadingVisual}</NavListLeadingVisual>
      )}
      {isActive ? <Box sx={{ fontWeight: 'bold', bg: 'actionListItem.default.selectedBg' }}>{children}</Box> : children}
      {trailingVisual && (
        <NavListTrailingVisual sx={{ display: 'flex', alignItems: 'center' }}>{trailingVisual}</NavListTrailingVisual>
      )}
    </NavListItem_>
  );
};

const NavListItem_ = NavList.Item;

/**
 * NOTE: SubNav must be a direct child of NavListItem.
 */
export const NavListSubNav = NavList.SubNav;

/**
 * Icon (or similar) positioned before `Item` text.
 *
 * NOTE: this is an alias for `ActionList.LeadingVisual`.
 */
export const NavListLeadingVisual = NavList.LeadingVisual;

/**
 * Icon (or similar) positioned after `Item` text.
 *
 * NOTE: this is an alias for `ActionList.TrailingVisual`.
 */
export const NavListTrailingVisual = NavList.TrailingVisual;

/**
 * Visually separates `Item`s or `Group`s in an `ActionList`.
 *
 * NOTE: this is an alias for ActionList.Divider.
 */
export const NavListDivider = NavList.Divider;

/**
 * Collects related `Items` in a `NavList`.
 * NOTE: this is a `ActionList.Divider` followed by an `ActionList.Group`.
 */
export const NavListGroup = ({ sx = {}, ...props }: React.ComponentProps<typeof NavList.Group>) => (
  // NavList.Group is an ActionList.Divider followed by an ActionList.Group.
  // The divider is hidden if the group is the first child of the list.
  // By default, ActionList.Group has a rule to suppress the margin if it's the first child.
  // But the existence of the divider means that the *hidden* divider is the first child,
  // giving the group a margin-top anyway.
  // This suppresses that margin for the first group, which is actually the *second* child.
  <NavListGroup_ {...props} sx={merge<BetterSystemStyleObject>({ '&:nth-child(2)': { marginTop: '0' } }, sx)} />
);

const NavListGroup_ = NavList.Group;
